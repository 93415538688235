<template>
    <div class="main-input" :class="{error: !inputModel.isValid, [inputModel.class]: inputModel.class}">
      <input type="number" 
        :placeholder="inputModel.placeholder" 
        :readonly="inputModel.readonly" 
        :min="inputModel.minlength" 
        :max="inputModel.maxlength" 
        v-model="inputModel.value"/>
        <div class="clearable" v-if="!inputModel.readonly && inputModel.clearable && inputModel.value" @click="inputModel.value = null" v-html="Cross"></div>
        <div class="error-icon" v-if="!inputModel.isValid" v-html="InfoCircle"></div>
    </div>   
</template>

<script>
import { inputMixin } from '@/utils/input-mixin'

export default {
  name: 'InputNumber',
  mixins: [inputMixin],

  methods: {
      validate() {
          if((this.inputModel.required && this.inputModel.value === null) || isNaN(this.inputModel.value)) {
            this.inputModel.isValid = false
            return
          }

          if(this.inputModel.maxlength && this.inputModel.value.length > this.inputModel.maxlength) {
            this.inputModel.isValid = false
            return
          }
          
          if(this.inputModel.minlength && this.inputModel.value.length < this.inputModel.minlength) {
            this.inputModel.isValid = false
            return
          }

          this.inputModel.isValid = true
      }
  },
}
</script>