<template>
  <div class="filters-form">
    <div class="filters-row">
      <div class="form-column">
        <div class="form-label">
          {{ labels.published_acquired_links_f_from }}
        </div>
        <div class="form-inline">
          <div><input-number v-model="published_acquired_links_f_from" /></div>
          <div><input-number v-model="published_acquired_links_f_to" /></div>
        </div>
      </div>

      <div class="form-column">
        <div class="form-label">
          {{ labels.date_published_acquired_links_f_from }}
        </div>
        <div class="form-inline">
          <div>
            <input-date-picker v-model="date_published_acquired_links_f_from" />
          </div>
          <div>
            <input-date-picker v-model="date_published_acquired_links_f_to" />
          </div>
        </div>
      </div>
    </div>

    <div class="filters-row">
      <div class="form-column">
        <div class="form-label">{{ labels.pending_acquired_links_f_from }}</div>
        <div class="form-inline">
          <div><input-number v-model="pending_acquired_links_f_from" /></div>
          <div><input-number v-model="pending_acquired_links_f_to" /></div>
        </div>
      </div>

      <div class="form-column">
        <div class="form-label">
          {{ labels.date_created_f_from }}
        </div>
        <div class="form-inline">
          <div>
            <input-date-picker v-model="date_created_f_from" />
          </div>
          <div>
            <input-date-picker v-model="date_created_f_to" />
          </div>
        </div>
      </div>
    </div>

    <div class="filters-row">
      <div class="form-column">
        <div class="form-label">{{ labels.status_f }}</div>
        <div class="form-inline">
          <div>
            <input-select
              v-model="status_f"
              @changeValue="changeValueStatus_f"
            />
          </div>
        </div>
      </div>

      <div class="form-column">
        <div class="form-label">{{ labels.date_status_changed_f_from }}</div>
        <div class="form-inline">
          <div><input-date-picker v-model="date_status_changed_f_from" /></div>
          <div><input-date-picker v-model="date_status_changed_f_to" /></div>
        </div>
      </div>
    </div>

    <div class="filters-row">
      <div class="form-column">
        <div class="form-label">{{ labels.invite_status_f }}</div>
        <div class="form-inline">
          <div>
            <input-select
              v-model="invite_status_f"
              @changeValue="changeValueInvite_status_f"
            />
          </div>
        </div>
      </div>
      <div class="form-column">
        <div class="form-label">{{ labels.date_owner_changed_f_from }}</div>
        <div class="form-inline">
          <div><input-date-picker v-model="date_owner_changed_f_from" /></div>
          <div><input-date-picker v-model="date_owner_changed_f_to" /></div>
        </div>
      </div>
    </div>

    <div class="filters-row">
      <div class="form-column">
        <div class="form-label">{{ labels.link_builder_f }}</div>
        <div class="form-inline">
          <div>
            <input-select
              v-model="link_builder_f"
              @changeValue="changeValueLink_builder_f"
            />
          </div>
        </div>
      </div>
      <div class="form-column"></div>
    </div>
  </div>
</template>

<script>
import InputDatePicker from '@/components/inputs/InputDatePicker'
import InputSelect from '@/components/inputs/InputSelect'
import InputNumber from '@/components/inputs/InputNumber'
import { fetchData, urls } from '@/utils/urls.js'
import { filtersMixin } from '@/utils/filters-mixin'

export default {
  name: 'SetFiltersClients',
  mixins: [filtersMixin],
  components: { InputDatePicker, InputSelect, InputNumber },

  data() {
    return {
      invite_status_f: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: true, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: true, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
        type: 'select',
      },

      status_f: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: true, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: true, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
        type: 'select',
      },

      pending_acquired_links_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },
      pending_acquired_links_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },

      published_acquired_links_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },
      published_acquired_links_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },

      link_builder_f: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: true, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: true, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
        type: 'select',
      },

      date_published_acquired_links_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'date',
      },

      date_published_acquired_links_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'date',
      },

      date_created_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'date',
      },

      date_created_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'date',
      },

      date_owner_changed_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'date',
      },

      date_owner_changed_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'date',
      },

      date_status_changed_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'date',
      },

      date_status_changed_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'date',
      },

      labels: {
        invite_status_f: 'Invite status',
        status_f: 'Client Status',
        pending_acquired_links_f_from: 'Pending Acquired Links',
        pending_acquired_links_f_to: 'Pending Acquired Links',
        published_acquired_links_f_from: 'Published Acquired Links',
        published_acquired_links_f_to: 'Published Acquired Links',
        link_builder_f: 'Link Builder',
        date_published_acquired_links_f_from:
          'Last Acquired Links Published Date',
        date_published_acquired_links_f_to:
          'Last Acquired Links Published Date',
        date_created_f_from: 'Date of creation of the Client card',
        date_created_f_to: 'Date of creation of the Client card',
        date_owner_changed_f_from: 'Owner Changed date',
        date_owner_changed_f_to: 'Owner Changed date',
        date_status_changed_f_from: 'Client Status Changed date',
        date_status_changed_f_to: 'Client Status Changed date',
      },
    }
  },

  created() {
    this.fetchDataStatus_f()
    this.fetchDataLink_builder_f()
    this.fetchDataInvite_status_f()

    for (let key in this.filters) {
      const element = this.filters[key]
      if (key in this.$data) {
        this.$data[key].value = element
      }
    }
  },

  methods: {
    changeValueInvite_status_f(value) {
      if (value?.length)
        this.$emit('setFilterValue', { key: 'invite_status_f', value: value })
      else this.$emit('setFilterValue', { key: 'invite_status_f', value: null })
    },
    changeValueStatus_f(value) {
      if (value?.length)
        this.$emit('setFilterValue', { key: 'status_f', value: value })
      else this.$emit('setFilterValue', { key: 'status_f', value: null })
    },

    changeValueLink_builder_f(value) {
      if (value?.length)
        this.$emit('setFilterValue', { key: 'link_builder_f', value: value })
      else this.$emit('setFilterValue', { key: 'link_builder_f', value: null })
    },

    async fetchDataInvite_status_f() {
      let query = `page=1&per_page=all&invite_status=asc`
      let result = await fetchData(urls.inviteStatuses, 'GET', null, query)

      this.invite_status_f.dataList = (result?.rows || []).map((item) => {
        return {
          id: item.invite_status,
          name: item.invite_status,
        }
      })
    },

    async fetchDataStatus_f() {
      let query = `page=1&per_page=all&client_status=asc`
      let result = await fetchData(urls.clientStatuses, 'GET', null, query)

      this.status_f.dataList = (result?.rows || []).map((item) => {
        return {
          id: item.client_status,
          name: item.client_status,
        }
      })
    },

    async fetchDataLink_builder_f() {
      let query = `page=1&per_page=all&email=asc`
      let result = await fetchData(urls.users, 'GET', null, query)

      this.link_builder_f.dataList = (result?.rows || []).map((item) => {
        return {
          id: item.email,
          name: item.email,
        }
      })
    },
  },
}
</script>
