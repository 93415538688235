<template>
  <div :class="{ 'colapsed-header': isColapsedBlock }">
    <colapse-button
      :isColapsedBlock="isColapsedBlock"
      @click="isColapsedBlock = !isColapsedBlock"
    />
    <div
      v-if="!isColapsedBlock"
      class="head-and-filters js-head-and-filters"
      :style="`right: ` + rightHeadPosition"
    >
      <header-page :title="'Clients'" :hasBackLink="false" />

      <div class="filters-container">
        <div class="filters-block">
          <div class="search">
            <input-text v-model="search_text" />
            <div
              v-if="isShowClear"
              class="main-button lg ml-15"
              @click="clearFilters"
            >
              <div>Clear</div>
            </div>
          </div>
          <div class="button">
            <div class="main-button lg orange" @click="isVisibleFilters = true">
              <div class="icon" v-html="Filter"></div>
              <div>Filters</div>
            </div>
          </div>
        </div>
        <div class="selected-filters" v-if="selectedFilters.length">
          <div
            class="selected-filters-groups"
            v-for="(group, key) in filterGroups"
            :key="key"
          >
            <div class="selected-filters-groups-item">
              <div
                class="icon"
                @click="removeFilterGroup(key)"
                v-html="Cross"
              ></div>
              <div class="text">{{ group[0].groupName }}</div>
              <div class="selected-filters-groups-item-sub-items">
                <template v-for="groupItem in group" :key="groupItem.text">
                  <div
                    v-if="!isArray(groupItem.text)"
                    class="selected-filters-item"
                  >
                    <div
                      class="icon"
                      @click="removeFilter(groupItem.id)"
                      v-html="Cross"
                    ></div>
                    <div class="text">
                      {{ groupItem.label }} {{ groupItem.text }}
                    </div>
                  </div>

                  <div
                    v-else
                    class="selected-filters-item"
                    v-for="groupItemArray in groupItem.text"
                    :key="groupItemArray"
                  >
                    <div
                      class="icon"
                      @click="
                        removeFilterArrayItem(groupItem.id, groupItemArray)
                      "
                      v-html="Cross"
                    ></div>
                    <div class="text">{{ groupItemArray }}</div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <grid
      v-model:columns="columns"
      v-model="rows"
      v-model:selectedRows="selectedRows"
      v-model:options="options"
      :page="page"
      :per_page="per_page"
      :newRowComponent="newRowComponent"
      :isHideNewRowComponent="isHideNewRowComponent"
      :countRows="countRows"
      :search_text="search_textToGrid"
      :listTabs="listTabs"
      @setTab="setTab"
      @deleteRow="deleteRow"
      @nextPage="nextPage"
      @prevPage="prevPage"
      @saveNewRow="saveNewEntity"
      @saveRow="saveEntity"
      @setPerPage="setPerPage"
      @sortRows="fetchData"
      @deleteSelected="deleteSelected"
      @exportSelected="exportSelected"
      @changedColumnsParams="changedColumnsParams"
    />
    <set-filters
      v-if="isVisibleFilters"
      v-model="isVisibleFilters"
      :setFilterComponent="setFilterComponent"
      :filters="filters"
      @setFilters="setFilters"
    />
  </div>
</template>

<style lang="scss">
.client-group-block {
  border: 1px solid #dddae9;
  border-radius: 15px;
  padding: 10px 15px 20px 15px;
  margin-bottom: 20px;
}
</style>

<script>
import { shallowRef } from 'vue'
import SetFilterComponent from '@/components/client/SetFilters/SetFiltersClients'
import { fetchData, fetchCSV, urls } from '@/utils/urls.js'
import { listMixin } from '@/utils/list-mixin'
import { hasAccess, permissions } from '@/utils/permissions'
import { GrigOptions } from '@/models/grid-options'
import { GridColumn } from '@/models/grid-column'

export default {
  name: 'Clients',
  mixins: [listMixin],

  data() {
    let colsKeyUrlIndex = 0
    let filterKeyUrlIndex = 0
    return {
      options: new GrigOptions({
        isCardEditable: true,
        pathToEdit: 'client',
        isSetColumns: true,
        isEditable: hasAccess(permissions.update_client),
        isDelete: hasAccess(permissions.delete_clients),
        isAdd: hasAccess(permissions.create_client),
        isExportToCSV: hasAccess(permissions.export_clients_to_csv),
        addButtonText: 'Add Client',
        getDeleteContent: async (rows) => {
          if (!rows) return ``

          let getHTML = (params) => {
            let stringHTML = ''
            for (const key in params) {
              if (typeof params[key] === 'object') {
                stringHTML += `
                  <div class="mt-10">
                    <span class="delete-label">${key.replaceAll(
                      '_',
                      ' '
                    )}</span>
                  </div>`

                for (const keyChild in params[key]) {
                  stringHTML += `
                      <div>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• ${params[key][keyChild]}</span>
                      </div>`
                }
              } else {
                stringHTML += `
                  <div class="mt-10">
                    <span class="delete-label">${key.replaceAll(
                      '_',
                      ' '
                    )}</span>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• ${params[key]}</div>
                  </div>`
              }
            }

            return stringHTML
          }

          let idsQuery = rows
            .map((row) => {
              return `client_ids=${row.id}`
            })
            .join('&')
          let result = ''
          let infoList = await fetchData(
            urls.deleteClientInfo,
            'GET',
            null,
            idsQuery
          )
          for (let info of infoList)
            result += `<div class="client-group-block">${getHTML(info)}</div>`

          return result
        },
      }),

      columns: [
        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'id',
          text: 'Client ID',
          positionDefault: colsKeyUrlIndex,
          width: '80px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'client_name',
          text: 'Client Name',
          positionDefault: colsKeyUrlIndex,
          formatedValue: ({ value, row, options }) => {
            if (value)
              return `<a href="${
                window.location.origin + '/' + options.pathToEdit + '/' + row.id
              }" target="_blank">${value}</a>`
            return value
          },
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'email',
          text: 'Client Email',
          positionDefault: colsKeyUrlIndex,
          formatedValue: ({ value }) => {
            if (value)
              return `<a href="mailto:${value}" target="_blank">${value}</a>`
            return value
          },
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'contact_name',
          text: 'Contact Name',
          positionDefault: colsKeyUrlIndex,
          width: '150px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'link_builder',
          text: 'Link Builder',
          positionDefault: colsKeyUrlIndex,
          width: '150px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'sites',
          text: 'Sites',
          positionDefault: colsKeyUrlIndex,
          formatedValue: ({ value, row }) => {
            let result = value

            let isContainsString = (value) => {
              if (!value || this.search_text.value?.length < 3) return false
              return (value + '')
                .toLowerCase()
                .includes((this.search_text.value + '').toLowerCase())
            }

            if (typeof value === 'object' && value?.length) {
              //short-list
              result = '<div class="short-list">'
              let iconShort = `
              <span class="colapse js-colapse" data-id="${row.id}">
                  <svg class="js-colapse" data-id="${row.id}" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="colapse js-colapse" data-id="${row.id}" fill-rule="evenodd" clip-rule="evenodd" d="M9.53033 0.96967C9.82322 1.26256 9.82322 1.73744 9.53033 2.03033L5.53033 6.03033C5.23744 6.32322 4.76256 6.32322 4.46967 6.03033L0.46967 2.03033C0.176777 1.73744 0.176777 1.26256 0.46967 0.96967C0.762563 0.676777 1.23744 0.676777 1.53033 0.96967L5 4.43934L8.46967 0.96967C8.76256 0.676777 9.23744 0.676777 9.53033 0.96967Z"/>
                </svg>
                </span>
              `
              let countShort = `(${value.length})`
              if (value.length < 2) {
                iconShort = ''
                countShort = ''
              }
              result += `<div><a class="bold-underline ${
                isContainsString(value[0]?.url) ? 'includes' : ''
              }" href="${
                value[0]?.url.includes('http')
                  ? value[0]?.url
                  : '//' + value[0]?.url
              }" title="${value[0]?.url}" target="_blank" >${
                value[0]?.url
              }${countShort}</a>
                ${iconShort}
                </div>`
              result += '</div>'

              //long-list
              result += '<div class="long-list">'
              let i = 0
              let iconLong = `
                <span class="colapse js-colapse" data-id="${row.id}">
                  <svg class="js-colapse" data-id="${row.id}" viewBox="0 0 10 7" transform="scale(-1 -1)" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="colapse js-colapse" data-id="${row.id}" fill-rule="evenodd" clip-rule="evenodd" d="M9.53033 0.96967C9.82322 1.26256 9.82322 1.73744 9.53033 2.03033L5.53033 6.03033C5.23744 6.32322 4.76256 6.32322 4.46967 6.03033L0.46967 2.03033C0.176777 1.73744 0.176777 1.26256 0.46967 0.96967C0.762563 0.676777 1.23744 0.676777 1.53033 0.96967L5 4.43934L8.46967 0.96967C8.76256 0.676777 9.23744 0.676777 9.53033 0.96967Z"/>
                  </svg>
                </span>`
              let countLong = `(${value.length})`
              for (const element of value) {
                if (i > 0) {
                  iconLong = ''
                  countLong = ''
                }
                result += `<div><a class="bold-underline ${
                  isContainsString(element?.url) ? 'includes' : ''
                }" href="${
                  element?.url.includes('http')
                    ? element?.url
                    : '//' + element?.url
                }" title="${element?.url}"target="_blank">${
                  element?.url
                }${countLong}</a>
                  ${iconLong}
                  </div>`
                i++
              }
              result += '</div>'
            }

            return result
          },
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'number_of_sites',
          text: 'Number of Sites',
          positionDefault: colsKeyUrlIndex,
          width: '100px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'published_acquired_links',
          text: 'Published Acquired Links',
          positionDefault: colsKeyUrlIndex,
          width: '100px',
          // formatedValue: ({ value }) => {
          //   let result = numberToCommasString(value)
          //   if (result !== 0 && !result) return value

          //   return `$${result}`
          // },
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'date_published_acquired_links',
          text: 'Last Acquired Links Published Date',
          positionDefault: colsKeyUrlIndex,
          width: '130px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'pending_acquired_links',
          text: 'Pending Acquired Links',
          positionDefault: colsKeyUrlIndex,
          width: '100px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'date_created',
          text: 'Date Of Creation Of The Client Card',
          positionDefault: colsKeyUrlIndex,
          width: '150px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'status',
          text: 'Client Status',
          positionDefault: colsKeyUrlIndex,
          width: '150px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'date_status_changed',
          text: 'Client Status Changed Date',
          positionDefault: colsKeyUrlIndex,
          width: '150px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'date_owner_changed',
          text: 'Owner Changed date',
          positionDefault: colsKeyUrlIndex,
          width: '150px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'invite_status',
          text: 'Invite status',
          positionDefault: colsKeyUrlIndex,
          width: '150px',
        }),
      ],
      rows: [],
      page: 1,
      per_page: 100,
      newRowComponent: shallowRef(/*NewRowEntity*/ null),
      setFilterComponent: shallowRef(SetFilterComponent),
      filters: {},
      listKey: 'clients',

      labels: {
        date_status_changed_f_from: 'Client Status Changed date',
        date_status_changed_f_to: 'Client Status Changed date',
      },

      groupToFilter: {
        invite_status_f: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'invite_status_f',
          name: 'Invite status',
        },
        status_f: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'status_f',
          name: 'Client Status',
        },
        pending_acquired_links_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'pending_acquired_links_f',
          name: 'Pending Acquired Links',
        },
        pending_acquired_links_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'pending_acquired_links_f',
          name: 'Pending Acquired Links',
        },
        published_acquired_links_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'published_acquired_links_f',
          name: 'Published Acquired Links',
        },
        published_acquired_links_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'published_acquired_links_f',
          name: 'Published Acquired Links',
        },
        link_builder_f: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'link_builder_f',
          name: 'Link Builder',
        },
        date_published_acquired_links_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'date_published_acquired_links_f',
          name: 'Last Acquired Links Published Date',
        },
        date_published_acquired_links_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'date_published_acquired_links_f',
          name: 'Last Acquired Links Published Date',
        },
        date_created_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'date_created_f',
          name: 'Date of creation of the Client card',
        },
        date_created_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'date_created_f',
          name: 'Date of creation of the Client card',
        },
        date_owner_changed_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'date_owner_changed_f',
          name: 'Owner Changed date',
        },
        date_owner_changed_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'date_owner_changed_f',
          name: 'Owner Changed date',
        },
        date_status_changed_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'date_status_changed_f',
          name: 'Client Status Changed date',
        },
        date_status_changed_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'date_status_changed_f',
          name: 'Client Status Changed date',
        },
      },
      isColapsedBlock: false,

      listTabs: [
        {
          id: 'all',
          name: 'All clients',
          count: 0,
          isActive: true,
          isVisible: true,
        },
      ],
      activeTab: 'all',
    }
  },

  created() {
    this.setDefaultColumnsParams() // 1
    this.setMetaDataColumnsParams() // 2
    this.setDataFromQueryParams() // 3
    this.loadCountTabs()
    this.fetchData()
  },

  methods: {
    async loadCountTabs() {
      let query = `page=1&per_page=1`

      let clientsAll = await fetchData(
        urls.clients,
        'GET',
        null,
        query + `&clients_f=all`
      )
      let clientsAllTab = this.listTabs.find((item) => item.id === 'all')
      clientsAllTab.count = clientsAll?.count || 0
    },

    setTab(id) {
      if (!id) return

      this.activateTab(id)
      this.activeTab = id
    },

    saveNewEntity(entity) {
      this.setNewEntity(entity)
      this.addRow()
    },

    async editRow(entity) {
      this.$store.commit('setIsLoading', true)
      const id = entity.id
      delete entity.id

      let result = await fetchData(
        urls.clients + `/${id}`,
        'POST',
        null,
        this.entityQuery(entity)
      )
      if (!result?.detail) {
        let entityRow = this.rows.find((item) => item.id === id)
        for (const key in entity) {
          if (key in entityRow) entityRow[key] = entity[key]
        }

        this.isHideNewRowComponent = true
        this.$nextTick(() => {
          this.isHideNewRowComponent = false
        })
      } else console.error(result.detail)

      this.$store.commit('setIsLoading', false)
    },

    async addRow() {
      this.$store.commit('setIsLoading', true)

      let result = await fetchData(
        urls.clients,
        'POST',
        null,
        this.entityQuery()
      )
      if (!result?.detail) {
        const tmpEntity = this.newEntity
        this.rows.unshift({ ...{ id: result.id }, ...tmpEntity })
        this.isHideNewRowComponent = true
        this.$nextTick(() => {
          this.isHideNewRowComponent = false
        })

        this.newEntityToDefault()
      } else {
        this.isHideNewRowComponent = false
        console.error(result.detail)
      }

      this.$store.commit('setIsLoading', false)
    },

    async deleteRow(ids) {
      let idsLocal = typeof ids === 'object' ? ids : [ids]
      if (!idsLocal || !idsLocal.length) return

      this.$store.commit('setIsLoading', true)

      const deleteEntityQuery = (ids) => {
        let query = ''
        for (const id of ids) {
          query += `client_ids=${id}&`
        }

        return query
      }

      let query = ''

      if (idsLocal?.length && idsLocal[0] === 'all') {
        query += query = `${this.queryToFetch(
          1,
          'all'
        )}&${this.queryToFilters()}&${
          this.queryToSort() & deleteEntityQuery(idsLocal)
        }`
      } else {
        query += deleteEntityQuery(idsLocal)
      }

      console.log('urls.clients', urls.clients)
      console.log('query', query)

      let result = await fetchData(urls.clients, 'DELETE', null, query)
      if (!result?.detail) {
        for (const id of idsLocal) {
          const idx = this.rows.findIndex((item) => item.id === id)
          this.rows.splice(idx, 1)
        }
        this.selectedRows = []
      } else console.error(result.detail)

      this.$store.commit('setIsLoading', false)
    },

    async exportToCSVRows(ids) {
      this.$store.commit('setIsLoading', true)

      const exportQuery = (ids) => {
        let query = '&'
        for (const id of ids) {
          query += `client_ids=${id}&`
        }

        if (query === '&') {
          for (const row of this.rows) {
            query += `client_ids=${row.id}&`
          }
        }

        if (this.options.isShowAllColumns) {
          query += `all_columns=true&`
        } else {
          query += `all_columns=false&`
        }

        return query
      }

      let query = ''

      if (ids?.length && ids[0] === 'all') {
        query += query = `${this.queryToFetch(
          1,
          'all'
        )}&${this.queryToFilters()}&${this.queryToSort()}`
      }

      query += exportQuery(ids)

      await fetchCSV(urls.clientsExportToCsv, 'GET', 'clients', null, query)

      this.$store.commit('setIsLoading', false)
    },

    async fetchData() {
      this.setQueryParams()

      this.$nextTick(() => {
        this.setHeightGridScrolableBlock()
      })
      if (this.isLoading) return

      this.$store.commit('setIsLoading', true)
      let query = ''
      let prevSearch_text = this.search_text.value

      query = `${this.queryToFetch()}&${this.queryToFilters()}&${this.queryToSort()}`

      let result = await fetchData(urls.clients, 'GET', null, query)

      this.rows = result?.rows || []

      this.countRows = result?.count || 0
      this.search_textToGrid = this.search_text.value
      this.$store.commit('setIsLoading', false)

      if (
        this.search_text.value?.length > 2 &&
        prevSearch_text !== this.search_text.value
      )
        this.fetchData()
    },

    queryToFilters() {
      let searchParams = new URLSearchParams()
      //let query = ''
      for (const key in this.filters) {
        if (typeof this.filters[key] === 'object') {
          for (const value of this.filters[key]) {
            //query +=`${key}=${value}&`
            searchParams.append(key, value)
          }
        } else {
          //query +=`${key}=${this.filters[key]}&`
          searchParams.append(key, this.filters[key])
        }
      }

      switch (this.activeTab) {
        case 'all':
          searchParams.append('clients_f', 'all')
          //query += `&clients_f=all`
          break

        case 'my-clients':
          searchParams.append('clients_f', 'my')
          //query += `&clients_f=my`
          break

        case 'my-div':
          searchParams.append('clients_f', 'div')
          //query += `&clients_f=div`
          break

        //default:
        //query += ``
      }

      //return query
      return searchParams.toString()
    },
  },
}
</script>
