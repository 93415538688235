export const filtersMixin = {
  emits: ['setFilterValue'],

  props: {
    filters: { default: () => ({}), type: Object },
  },

  mounted() {
    // adding dynamic watchers of form fields
    for (const key in this.$data) {
      let field = this.$data[key]
      if (field && typeof field === 'object' && 'value' in field) {
        this.$watch(`${key}.value`, () => {
          let value = null

          if (field.type === 'select')
            value = field.value?.length ? field.value : null
          else if (field.type === 'date')
            value = this.formatDateToQuery(field.value)
          else value = field.value

          this.$emit('setFilterValue', {
            key: key,
            value: value,
          })
        })
      }
    }
  },

  methods: {
    formatDateToQuery(date) {
      if (
        date &&
        Object.prototype.toString.call(date) === '[object Date]' &&
        !isNaN(date)
      ) {
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()

        day = day < 10 ? '0' + day : day
        month = month < 10 ? '0' + month : month

        return `${year}-${month}-${day}`
      } else if (date) {
        return date
      }

      return null
    },
  },
}
